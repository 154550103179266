import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router } from "react-router-dom";
import { OrochiProvider } from "./context/OrochiContext";
import { ParallaxProvider } from "react-scroll-parallax";
import GlobalStyles from "./GlobalStyles";
import "./i18n";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OrochiProvider>
      <GlobalStyles />
      <HelmetProvider>
        <ParallaxProvider scrollAxis="vertical">
          <Router history={history}>
            <App />
          </Router>
        </ParallaxProvider>
      </HelmetProvider>
    </OrochiProvider>
  </React.StrictMode>
);
