import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer";
import Sphere from "../components/Sphere";
import { SEO } from "../SEO";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { useOrochi } from "../context/OrochiContext";
import getResponsiveImageUrl from "../utils/getResponsiveImageUrl";
import ResponsiveImage from "../components/Img/ResponsiveImage";

const About = () => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref3, inView3] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const {
    dispatch,
    state: { circleButtonAbout, navbarToggle },
  } = useOrochi();
  useEffect(() => {
    let views = [inView1, inView2, inView3];
    views.map(
      (el, ind) =>
        el === true && dispatch({ type: "view-index-about", payload: ind + 1 })
    );
  }, [dispatch, inView1, inView2, inView3]);
  const circleButtonFn = useCallback(
    (value) => {
      dispatch({ type: "change-circle-about", payload: value });
    },
    [dispatch]
  );
  return (
    <AboutDiv>
      <SEO
        item={{
          title: t("about-title"),
          description: t("about-description"),
          url: "https://www.drunkendragon.ca/about",
        }}
      />
      <CircleButtons circle={{ circleButtonAbout, navbarToggle }}>
        <li>
          <a
            href={`#${t("about-section1")}`}
            aria-label="navigate to the first one"
            onClick={() => circleButtonFn(1)}
            className={circleButtonAbout === 1 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("about-section2")}`}
            aria-label="navigate to the second one"
            onClick={() => circleButtonFn(2)}
            className={circleButtonAbout === 2 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("about-section3")}`}
            aria-label="navigate to the third one"
            onClick={() => circleButtonFn(3)}
            className={circleButtonAbout === 3 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
      </CircleButtons>
      <FirstPart
        id={t("about-section1")}
        ref={ref1}
        view1={{
          inView1: inView1 ? "true" : "false",
        }}
        bgImage={getResponsiveImageUrl(
          windowWidth >= 991
            ? "about_us/drunken-dragon-about-us-desktop.webp"
            : "about_us/drunken-dragon-about-us-mobile.webp"
        )}
      >
        {/* <div>
          <h1>{t("about-h-o")}</h1>
          <h2>について</h2>
        </div> */}
        <main>
          <h1>{t("about-h-o")}</h1>
          <p>{t("about-p-o")}</p>
          <p>{t("about-p-t")}</p>
          <p>{t("about-p-three")}</p>
          <p>{t("about-p-four")}</p>
          <p>{t("about-p-five")}</p>
          <p>{t("about-p-six")}</p>
        </main>
        <ResponsiveImage src={"about_us/about-us_flower.webp"} alt="flower" />
      </FirstPart>
      <SecondPart
        id={t("about-section2")}
        allView={{ inView2, inView1, inView3 }}
        view2={inView2}
        ref={ref2}
      >
        <main>
          <section>
            {/* <h1>最高の</h1> */}
            <h1>{t("about-t-h-o")}</h1>
            <p>{t("about-t-p-o")}</p>
            <p>{t("about-t-p-t")}</p>
          </section>
        </main>
        <PlateOneDiv>
          <ResponsiveImage src={"plates/plate-01.webp"} alt="plate-one" />
        </PlateOneDiv>
        <PlateTwoDiv>
          <ResponsiveImage src={"plates/plate-02.webp"} alt="plate-two" />
        </PlateTwoDiv>
        <MovingTextOne>
          <ResponsiveImage src={"about_us/about-us_text.webp"} alt="text" />
        </MovingTextOne>
        <MovingTextTwo>
          <ResponsiveImage src={"about_us/about-us_text.webp"} alt="text" />
        </MovingTextTwo>
        <MovingTextThree>
          <ResponsiveImage src={"about_us/about-us_text.webp"} alt="text" />
        </MovingTextThree>
        <MovingTextFour>
          <ResponsiveImage src={"about_us/about-us_text.webp"} alt="text" />
        </MovingTextFour>
      </SecondPart>
      <ThirdPart
        id={t("about-section3")}
        view2={inView3}
        ref={ref3}
        samesite="lax"
      >
        <ThirdPartContent>
          <div>
            <h2>{t("about-three-h")}</h2>
            <p>{t("about-three-p")}</p>
          </div>
          <Sphere />
        </ThirdPartContent>
      </ThirdPart>
      <Footer />
    </AboutDiv>
  );
};
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const AboutDiv = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
`;
const CircleButtons = styled.ul`
  z-index: 100;
  position: fixed;
  transition: 0.6s ease-in-out;
  right: 40px;
  /* left: ${(props) => (props.circle.navbarToggle ? "190px" : "50px")}; */
  top: 50%;
  transform: translate(calc(-50% + 15px), -50%);
  /* transform: translate(0, -145px); */
  /* margin-left: 25px; */
  /* padding-left: ${(props) =>
    props.circle.navbarToggle ? "10px" : "0px"}; */
  background-color: inherit;
  & > li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 2px solid var(--silver);
    width: 25px;
    height: 25px;
    margin-bottom: 39px;
    & > a:hover {
      background-color: var(--silver);
      transition: background-color 0.4s;
    }
    & > a {
      width: 17px;
      height: 17px;
      border-radius: 15px;
      cursor: pointer;
      &.active {
        background-color: var(--silver);
        transition: background-color 0.4s;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    display: none;
  }
`;
const FirstPart = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 101vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 120px 0 0 150px;
  & > main {
    margin-bottom: 3%;
    text-align: center;
    width: 100%;
    max-width: 1000px;
    height: auto;
    padding: 20px;
    color: var(--white);
    & > h1 {
      animation: ${fadeIn} 0.5s ease-in;
      margin-bottom: 32px;
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
    }
    & > p {
      animation: ${fadeIn} 1.5s ease-in;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
  }
  & > img {
    position: absolute;
    right: 0;
    bottom: -32%;
    width: 30%;
    z-index: 3;
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    padding: 20px;
    justify-content: center;
    align-items: center;
    height: auto;
    background-size: contain;
    background-position: top;
    & > main {
      padding: 0;
      width: 100%;
      left: unset;
      right: 0;
      top: 0;
      width: 100%;
      transform: unset;
      margin: 0;
      padding: 80vw 0 40px 0;
      opacity: 1;
      & > h1 {
        opacity: 1;
        font-size: 28px;
        line-height: 30px;
      }
      & > p {
        opacity: 1;
      }
    }
    & > img {
      bottom: -100px;
      width: 100%;
      max-width: 300px;
    }
  }
`;
const slideSideways = keyframes`
  from {
    opacity: 0.4;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const slideSideways2 = keyframes`
  from {
    opacity: 0.4;
    transform: translateX(+50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const slideSidewaysOpp = keyframes`
  from {
    opacity: 0.4;
    transform: translateX(0%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
`;
const slideSidewaysOpp2 = keyframes`
  from {
    opacity: 0.4;
    transform: translateX(0);
  }
  to {
    opacity: 1;
    transform: translateX(+50%);
  }
`;
const SecondPart = styled.div`
  position: relative;
  width: 100%;
  height: 105vh;
  background-color: #101010;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > main {
    padding: 15px 0 15px 150px;
    width: 100%;
    max-width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 50px;
    border: 2px solid
      linear-gradient(90deg, rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.5));
    /* background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)); */
    gap: 20px;
    color: var(--white);
    z-index: 2;
    text-align: center;
    & > img {
      width: 100%;
      max-width: 250px;
      z-index: 2;
      height: 100%;
      max-height: 358.6px;
      display: none;
    }
    & > section {
      width: 100%;
      z-index: 2;
      & > h1 {
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
      }
      & > p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }
      & > p:last-child {
        margin-top: 0;
      }
    }
  }
  div:nth-child(4) {
    animation: ${(props) =>
      props.allView.inView1 === true &&
      props.allView.inView2 === true &&
      css`
        ${slideSideways} 3s ease-in-out
      `};
    animation: ${slideSideways} 3s ease-in-out;
    animation: ${(props) =>
      props.allView.inView1 === true &&
      css`
        ${slideSidewaysOpp} 3s ease-in-out
      `};
    animation: ${(props) =>
      props.allView.inView3 === true &&
      css`
        ${slideSidewaysOpp} 3s ease-in-out
      `};
  }
  div:nth-child(5) {
    /* animation: ${(props) =>
      props.allView.inView2 === true &&
      css`
        ${slideSideways2} 3s ease-in-out
      `}; */
    animation: ${slideSideways2} 3s ease-in-out;
    animation: ${(props) =>
      props.allView.inView1 === true &&
      css`
        ${slideSidewaysOpp2} 3s ease-in-out
      `};
    animation: ${(props) =>
      props.allView.inView3 === true &&
      css`
        ${slideSidewaysOpp2} 3s ease-in-out
      `};
  }
  div:nth-child(6) {
    /* animation: ${(props) =>
      props.allView.inView2 === true &&
      css`
        ${slideSideways} 3s ease-in-out
      `}; */
    animation: ${slideSideways} 3s ease-in-out;
    animation: ${(props) =>
      props.allView.inView1 === true &&
      css`
        ${slideSidewaysOpp} 3s ease-in-out
      `};
    animation: ${(props) =>
      props.allView.inView3 === true &&
      css`
        ${slideSidewaysOpp} 3s ease-in-out
      `};
  }
  div:nth-child(7) {
    /* animation: ${(props) =>
      props.allView.inView2 === true &&
      css`
        ${slideSideways2} 3s ease-in-out
      `}; */
    animation: ${slideSideways2} 3s ease-in-out;
    animation: ${(props) =>
      props.allView.inView1 === true &&
      css`
        ${slideSidewaysOpp2} 3s ease-in-out
      `};
    animation: ${(props) =>
      props.allView.inView3 === true &&
      css`
        ${slideSidewaysOpp2} 3s ease-in-out
      `};
  }
  @media screen and (max-width: 1240px) {
    & > main {
      padding: 15px 40px;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    & > main {
      width: 90%;
      display: block;
      background: none;
      & > img {
        width: 100%;
        max-width: 380px;
        max-height: 522px;
        margin-bottom: 40px;
      }
      & > section {
        width: 100%;
        & > h1 {
          font-size: 28px;
          line-height: 30px;
        }
        & > p {
          font-size: 16px;
          line-height: 30px;
          font-weight: 300;
          margin: 20px auto;
          max-width: 380px;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    height: auto;
    min-height: 102vh;
    padding: 100px 0;
    & > main {
      text-align: center;
      width: 100%;
      display: block;
      background: none;
      margin-top: 80px;
      margin-bottom: 50px;
      padding: 20px;
      & > img {
        width: 100%;
        max-width: 230px;
        margin-bottom: 40px;
      }
      & > section {
        width: 100%;
        & > h1 {
          font-size: 28px;
          line-height: 30px;
        }
        & > p {
          font-size: 16px;
          line-height: 30px;
          font-weight: 300;
        }
      }
    }
  }
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
const PlateOneDiv = styled.div`
  z-index: 3;
  position: absolute;
  color: var(--silver);
  text-align: left;
  left: 150px;
  top: 40px;
  width: 50%;
  transform-style: preserve-3d;
  max-width: 500px;
  max-height: 500px !important;
  display: flex;
  justify-content: flex-start;
  & > img {
    height: 100%;
    width: 100%;
    max-width: 420px;
    transition: all 0.4s;
    transform-style: preserve-3d;
    animation: ${rotate} 100s infinite linear;
  }
  @media screen and (max-width: 1460px) {
    max-width: 350px;
    right: 40px;
  }
  @media screen and (max-width: 1240px) {
    max-width: 300px;
    left: 40px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    left: 40px;
    max-width: 250px;
  }
  @media screen and (max-width: 750px) {
    /* start of phone styles */
    left: 20px;
    max-width: 150px;
  }
`;
const PlateTwoDiv = styled.div`
  z-index: 3;
  position: absolute;
  color: var(--silver);
  text-align: right;
  right: 40px;
  bottom: 40px;
  width: 50%;
  transform-style: preserve-3d;
  max-width: 500px;
  max-height: 500px !important;
  display: flex;
  justify-content: flex-end;
  & > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-width: 420px;
    transition: all 0.4s;
    transform-style: preserve-3d;
    animation: ${rotate} 100s infinite linear;
    &:hover {
      transform: rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px);
    }
  }
  @media screen and (max-width: 1460px) {
    max-width: 350px;
    right: 40px;
  }
  @media screen and (max-width: 1240px) {
    max-width: 300px;
    right: 40px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    right: 40px;
    max-width: 250px;
  }
  @media screen and (max-width: 750px) {
    /* start of phone styles */
    z-index: 1;
    width: 100%;
    max-width: 150px;
    right: 20px;
  }
`;
const MovingTextOne = styled.div`
  position: absolute;
  width: 100%;
  top: 8%;
  z-index: 1;
  & > img {
    width: 100%;
    height: 100%;
    max-height: 184.5px;
  }
`;
const MovingTextTwo = styled.div`
  position: absolute;
  width: 100%;
  top: 30%;
  z-index: 1;
  & > img {
    width: 100%;
    height: 100%;
    max-height: 184.5px;
  }
`;
const MovingTextThree = styled.div`
  position: absolute;
  width: 100%;
  top: 52%;
  z-index: 1;
  & > img {
    width: 100%;
    height: 100%;
    max-height: 184.5px;
  }
`;
const MovingTextFour = styled.div`
  position: absolute;
  width: 100%;
  top: 74%;
  z-index: 1;
  & > img {
    width: 100%;
    height: 100%;
    max-height: 184.5px;
  }
`;
const ThirdPart = styled.div`
  height: 102vh;
  width: 100%;
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  font-size: 20px;
  margin: 0;
  background: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 150px;
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    padding-left: 0;
    height: auto;
  }
`;
const ThirdPartContent = styled.div`
  opacity: 0.9;
  width: 85%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 30px;
  border-radius: 50px;
  border: 2px solid
    linear-gradient(90deg, rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.5));
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
  & > div {
    flex-basis: 40%;
    & > h2 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
    }
    & > p {
      margin-top: 20px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    flex-flow: column nowrap;
    width: 100%;
    gap: 20px;
    & > div {
      flex-flow: column nowrap;
      flex-basis: 100%;
      & > h2 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      & > p {
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 60px 20px;
  }
`;

export default About;
