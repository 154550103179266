export const initialState = {
  circleButton: 1,
  navbarToggle: window.innerWidth > 991,
  menuButtons: "menu",
  foodMenuImageName: "OKONOMIYAKI",
  menuTab: "menu",
  isScrolling: false,
  logoIsVisible: true,
  circleButtonAbout: 1,
  drinksData: [],
  foodData: [],
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "change-circle":
      return { ...state, circleButton: payload, navbarToggle: false };
    case "navbar-toggle":
      return {
        ...state,
        navbarToggle: payload,
      };
    case "view-index":
      return {
        ...state,
        circleButton: payload,
        circleButtonToggle: !state.circleButtonToggle,
      };
    case "bouncy-up-arrow":
      return { ...state, circleButton: payload };
    case "bouncy-down-arrow":
      if (state.circleButton < 8)
        return { ...state, circleButton: state.circleButton + 1 };
      break;
    case "menu-buttons":
      return { ...state, menuButtons: payload };
    case "change-menu-bg":
      return { ...state, foodMenuImageName: payload };
    case "menu-tab":
      return { ...state, menuTab: payload };
    case "scrolling":
      return { ...state, isScrolling: payload };
    case "logo":
      return { ...state, logoIsVisible: payload };
    case "change-circle-about":
      return { ...state, circleButtonAbout: payload, navbarToggle: false };
    case "view-index-about":
      return {
        ...state,
        circleButtonAbout: payload,
      };
    case "SET_DRINKS_DATA":
      return { ...state, drinksData: payload };
    case "SET_FOOD_DATA":
      return { ...state, foodData: payload };
    default:
      return state;
  }
};
