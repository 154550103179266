import React, { memo } from "react";

const GoogleMap = () => {
  return (
    <>
      <iframe
        title="google-maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44841.202876365336!2d-73.74081072167182!3d45.37756697910002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91bfa372112f3%3A0x84343f2ce23c89ba!2sPlayground!5e0!3m2!1sen!2sca!4v1665674855905!5m2!1sen!2sca"
        width="600"
        height="450"
        style={{ border: "0", borderRadius: "12px" }}
        loading="eager"
        allowFullScreen={false}
        referrerPolicy="no-referrer-when-downgrade"
        samesite="strict"
        secure="none"
      ></iframe>
    </>
  );
};

export default memo(GoogleMap);
