import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

const defaultLanguage = "en"; // Default language if no previous selection is found

// Get the previously selected language from browser storage
const selectedLanguage = localStorage.getItem("language");

// Set the initial language from storage or use the language from URL
let languageFromURL = selectedLanguage || defaultLanguage;
if (window.location.pathname === "/fr" || window.location.pathname === "/menu/fr") {
  languageFromURL = "fr";
  localStorage.setItem("language", "fr");
} else {
  localStorage.setItem("language", languageFromURL);
}

//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en", // Default language if detection fails
    lng: languageFromURL, // Set initial language from URL
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

// Set the language in browser storage when it changes
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem("language", lng);
  languageFromURL = lng;
};

export default i18n;
