import {
  useRef,
  memo,
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from "react";
import styled from "styled-components";

const items = [
  {
    name: "BABEL",
    image: "/img/sphere/BABEL-LOGO-FOOTER.svg",
    url: "",
  },
  {
    name: "PLAYGROUND",
    image: "/img/sphere/PLAYGROUND-LOGO-DARK-FOOTER.svg",
    url: "https://playground.ca/",
  },
  {
    name: "THE RAIL",
    image: "/img/sphere/RAIL-LOGO-FOOTER.svg",
    url: "https://www.therail.ca/",
  },
  {
    name: "DRUNKED DRAGON",
    image: "/img/sphere/OROCHI-LOGO-FOOTER.svg",
    url: "https://drunkendragon.ca/",
  },
  {
    name: "ETC",
    image: "/img/sphere/ETC_logo_footer.svg",
    url: "https://playgroundetc.ca/",
  },
  {
    name: "ESPERANTO",
    image: "/img/sphere/ESPERANTO-LOGO-FOOTER.svg",
    url: "",
  },
];

const computePosition = (idx, random = false, size) => {
  if (random) idx = Math.floor(Math.random() * (items.length + 1));

  const phi = Math.acos(-1 + (2 * idx + 1) / items.length);
  const theta = Math.sqrt((items.length + 1) * Math.PI) * phi;

  return {
    x: (size * Math.cos(theta) * Math.sin(phi)) / 2,
    y: (size * Math.sin(theta) * Math.sin(phi)) / 2,
    z: (size * Math.cos(phi)) / 2,
  };
};

const CreateTag = (idx, text, size, url) => {
  const tagRef = useRef(null);
  return {
    idx: idx,
    text: text,
    url: text.url,
    opacity: 0,
    filter: "alpha(opacity=0)",
    transform: "translate3d(-50%, -50%, 0) scale(1)",
    tagRef: tagRef,
    ...computePosition(idx, false, size),
  };
};

const createInitialState = (size) => {
  return items.map((text, i) => {
    return CreateTag(i, text, size);
  });
};

const { radius, maxSpeed, initSpeed, direction } = {
  radius: 300,
  maxSpeed: 20,
  initSpeed: 40,
  direction: 135,
};
const size = 1.5 * radius;
const size2 = 0.8 * radius;
const depth = 1 * radius;

const Sphere = memo(() => {
  const tagCloudRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [items, setItems] = useState(
    createInitialState(windowWidth > 991 ? size : size2)
  );

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowWidth]);

  const mouseX0 = useRef(initSpeed * Math.sin(direction * (Math.PI / 180))) // prettier-ignore
  const mouseY0 = useRef(-initSpeed * Math.cos(direction * (Math.PI / 180))) // prettier-ignore
  const mouseX = useRef(mouseX0.current);
  const mouseY = useRef(mouseY0.current);
  const next = useCallback(() => {
    const a = -(Math.min(Math.max(-mouseY.current, -size), size) / radius) * maxSpeed // prettier-ignore
    const b = (Math.min(Math.max(-mouseX.current, -size), size) / radius) * maxSpeed // prettier-ignore

    if (Math.abs(a) <= 0.01 && Math.abs(b) <= 0.01) return; // pause

    // calculate offset
    const l = Math.PI / 180;
    const sc = [
      Math.sin(a * l),
      Math.cos(a * l),
      Math.sin(b * l),
      Math.cos(b * l),
    ];

    setItems((prev) => {
      const newItems = prev.map((item) => {
        const rx1 = item.x;
        const ry1 = item.y * sc[1] + item.z * -sc[0];
        const rz1 = item.y * sc[0] + item.z * sc[1];
        const rx2 = rx1 * sc[3] + rz1 * sc[2];
        const ry2 = ry1;
        const rz2 = rz1 * sc[3] - rx1 * sc[2];
        const per = (2 * depth) / (2 * depth + rz2);
        item.scale = Number(per.toFixed(3));
        let alpha = per * per - 0.25;
        alpha = Number((alpha > 1 ? 1 : alpha).toFixed(3));

        if (item?.tagRef?.current) {
          const left = (item.x - item.tagRef.current.offsetWidth / 2).toFixed(
            2
          );
          const top = (item.y - item.tagRef.current.offsetHeight / 2).toFixed(
            2
          );

          return {
            ...item,
            x: rx2,
            y: ry2,
            z: rz2,
            opacity: alpha,
            transform: `translate3d(${left}px, ${top}px, 0) scale(${item.scale})`,
            filter: `alpha(opacity=${100 * alpha})`,
          };
        }
        return { ...item };
      });

      return newItems;
    });
  }, []);

  useEffect(() => {
    if (tagCloudRef.current) {
      const interval = setInterval(next, 100);
      return () => clearInterval(interval);
    }
  }, [tagCloudRef, next]);
  // URLs may contain dynamic script content. So, always validate the URL to ensure the links are http: or https: to avoid javascript: URL-based script injection. Use the native URL parsing function to validate the URL and match the parsed protocol property to an allow list.
  // const  validateURL = (url) => {
  //   const parsed = new URL(url)
  //   return ['https:', 'http:'].includes(parsed.protocol)
  // }
  return (
    <MainDiv>
      <TagCloudDiv
        ref={tagCloudRef}
        onMouseMove={(ev) => {
          if (tagCloudRef.current) {
            const rect = tagCloudRef.current.getBoundingClientRect();
            mouseX.current = (ev.clientX - (rect.left + rect.width / 2)) / 5;
            mouseY.current = (ev.clientY - (rect.top + rect.height / 2)) / 5;
          }
        }}
        style={{
          position: "relative",
          width: `${2 * radius}px`,
          height: `${2 * radius}px`,
        }}
        samesite="Lax"
      >
        {items.map((item) => (
          <TagCloudItemDiv
            key={item.idx}
            href={item.url ? item?.url : undefined}
            // onClick={() => console.log(item.url)}
            target={item.url ? "_blank" : undefined}
            ref={item.tagRef}
            style={{
              filter: item.filter,
              opacity: item.opacity,
              transform: item.transform,
            }}
          >
            <img src={item.text.image} alt="logo" />
          </TagCloudItemDiv>
        ))}
      </TagCloudDiv>
    </MainDiv>
  );
});
const MainDiv = styled.div`
  background-color: inherit;
  box-sizing: border-box;
  color: var(--white);
  font-size: 20px;
  margin: 0;
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    height: auto !important;
  }
  `;
const TagCloudDiv = styled.div`
  cursor: pointer;
  align-self: center;
  justify-self: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    background-color: none;
    width: 100% !important;
    height: 350px !important;
  }
`;
const TagCloudItemDiv = styled.a`
  left: 50%;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s;
  will-change: transform, opacity, filter;
  z-index: 10;
  & > img {
    width: 100%;
    height: 100%;
    max-height: 50px;
  }
`;
export default Sphere;
