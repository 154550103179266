import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Page404 = () => {
  return (
    <ErrorDiv>
      <h1>Page 404</h1>
      <Link to="/">Orochi</Link>
    </ErrorDiv>
  );
};
const ErrorDiv = styled.div`
  background-color: #101010;
  color: var(--silver);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h1 {
    font-size: 150px;
    color: var(--gold_logo);
    font-weight: 300;
    text-transform: uppercase;
  }
  & > a {
    font-size: 80px;
    &:hover {
      color: var(--gold_logo);
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    h1 {
      font-size: 70px;
    }
  }
`;
export default Page404;
