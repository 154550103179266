import { useRef, useEffect } from "react";
import { useOrochi } from "../context/OrochiContext";

const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const { dispatch, state: { logoIsVisible } } = useOrochi();

  const callbackFunction = (entries) => {
    const [entry] = entries;
    dispatch({ type: "logo", payload: entry.isIntersecting });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run this effect only once

  return [containerRef, logoIsVisible];
};

export default useElementOnScreen;
