import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent"; // getCookieConsentValue, // Cookies,
import { useOrochi } from "./context/OrochiContext";
import styled, { keyframes } from "styled-components";
import Navbar from "./components/Navbar";
import Homepage from "./pages/Homepage";
import Menu from "./pages/Menu";
import Order from "./pages/Order";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import Page404 from "./pages/Page404";
// import FoodDetail from "./pages/FoodDetail";
// import pgLogo from "./images/PLAYGROUND-LOGO.svg";
import { changeLanguage } from "./i18n";
import ReactGA from "react-ga";
import { SEO } from "./SEO";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import TheLegend from "./pages/TheLegend";

const RouteChangeTracker = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-VFYJD9HD64");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <div></div>;
};

function App() {
  const {
    dispatch,
    state: { navbarToggle },
  } = useOrochi();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  ReactGA.initialize("G-FHBJQJ8K19");
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };

  window.onbeforeunload = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <MainDiv>
      <RouteChangeTracker />
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="loading">
          <SEO
            item={{
              title: t("seo-title"),
              description: t("seo-description"),
              url: "https://drunkendragon.ca",
              image: "/seo_images/drunken-dragon.jpg",
            }}
          />
          <Navbar />
          <NavbarDiv>
            <StyledBurger
              key={navbarToggle}
              aria-label="hamburger"
              open={navbarToggle}
              onClick={() => {
                dispatch({
                  type: "navbar-toggle",
                  payload: navbarToggle ? false : true,
                });
              }}
            >
              {" "}
              <div />
              <div />
              <div />
            </StyledBurger>
          </NavbarDiv>
          {/* {!navbarToggle ? (
            <ArrowDiv navbarToggle={navbarToggle}>
              <AiOutlineCaretLeft
                onMouseOver={() =>
                  dispatch({
                    type: "navbar-toggle",
                    payload: true,
                  })
                }
              />
            </ArrowDiv>
          ) : (
            <ArrowDiv navbarToggle={navbarToggle}>
              <AiOutlineCaretRight
                onMouseOver={() =>
                  dispatch({
                    type: "navbar-toggle",
                    payload: true,
                  })
                }
              />
            </ArrowDiv>
          )} */}
          {/* {!navbarToggle ? (
            <NavLink to="/" end>
              <LogoImg src={SmallIcon} alt="logo" />
            </NavLink>
          ) : null} */}
          {(location.pathname === "/" ||
            location.pathname === "/fr" ||
            location.pathname === "/menu" ||
            location.pathname === "/menu/fr" ||
            location.pathname === "/about" ||
            location.pathname === "/the-legend" ||
            location.pathname === "/contact") && (
            <ReserveTable
              theme={navbarToggle}
              href="https://widgets.libroreserve.com/WEB/QC016855598727/book"
              target="_blank"
              rel="noreferrer"
            >
              <span>{t("reserve.button")}</span>
            </ReserveTable>
          )}
          {/* {(location.pathname === "/" || location.pathname === "/fr") && (
            <Img src={pgLogo} alt="pg-logo" />
          )} */}
          <Language
            theme={{ navbarToggle, location }}
            onClick={() =>
              handleLanguageChange(i18n.language === "en" ? "fr" : "en")
            }
          >
            <div>{i18n.language === "en" ? "fr" : "en"}</div>
          </Language>
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Ok"
            declineButtonText={i18n.language === "en" ? "Decline" : "Refuser"}
            cookieName="orochi"
            // cookieValue={JSON.stringify(state)}
            style={{}}
            buttonStyle={{}}
            expires={999}
            sameSite="strict"
            secure={true}
          >
            {i18n.language === "en"
              ? "This website collects cookies to deliver better user experience"
              : "Ce site web collecte des cookies afin d'offrir une meilleure expérience utilisateur."}{" "}
            <span style={{ fontSize: "12px" }}>
              {i18n.language === "en"
                ? "We collect cookies to analyze our website traffic and performance; we never collect any personal data."
                : 'Nous collectons des témoins ("cookies") pour analyser le trafic et les performances de notre site web; nous ne collectons jamais de données personnelles.'}
            </span>
          </CookieConsent>
          <Routes>
            <Route exact="true" path="/" element={<Homepage />} />
            <Route path="/fr" element={<Homepage />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/menu/fr" element={<Menu />} />
            <Route path="/order" element={<Order />} />
            <Route path="/about" element={<About />} />
            <Route path="/the-legend" element={<TheLegend />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/menu/:id" element={<FoodDetail />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </I18nextProvider>
    </MainDiv>
  );
}
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
// const Img = styled.img`
//   position: absolute;
//   left: calc(50%);
//   top: 90%;
//   transform: translate(-50%, -50%);
//   z-index: 10;
//   width: 100%;
//   max-width: 200px;
//   @media screen and (max-width: 479px) {
//     max-width: 150px;
//     right: 20px;
//   }
// `;
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  //temporarily
  /* overflow: hidden; */
  .CookieConsent {
    background: rgba(0, 0, 0, 0.7) !important;
    color: var(--silver) !important;
    & > div:first-child {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.6);
      & > span {
        margin-top: 5px;
        color: var(--silver) !important;
      }
    }
    & > div:nth-child(2) {
      align-self: center;
      & > button {
        font-size: 16px;
        border: none;
        background: inherit !important;
        color: var(--silver) !important;
        background-color: rgba(0, 0, 0, 0.2) !important;
        &:hover {
          background-color: var(--gold_logo) !important;
          color: #101010 !important;
        }
      }
      & > button:nth-child(2) {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.6) !important;
        &:hover {
          background-color: var(--gold_logo) !important;
          color: #101010 !important;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    /* start of medium tablet styles */
    position: relative;
    overflow-x: hidden;
  }
`;
const NavbarDiv = styled.div`
  position: fixed;
  top: 0;
  z-index: 11;
  background: white;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 27%,
    rgba(0, 0, 0, 0.8) 100%
  );
  padding: 0 20px;
  @media screen and (min-width: 1051px) {
    /* start of medium tablet styles */
    /* display: none; */
    height: 120px;
    padding: 0;
    right: 0;
    left: 0;
  }
  @media screen and (max-width: 1050px) {
    /* start of medium tablet styles */
    /* display: flex; */
  }
`;
const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: transparent;
  border: none;
  font-size: unset;
  padding: 0;
  font-size: unset;
  border-radius: unset;
  cursor: pointer;
  padding: 0;
  z-index: 13;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: unset;
  }
  & > div {
    width: 34px;
    height: 2px;
    background: var(--silver);
    border-radius: 10px;
    position: relative;
    transform-origin: 4.5px;
    padding-bottom: 2px;
    animation: ${({ open }) => !open && fadeIn} 0.4s ease-in-out;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      margin-bottom: 7px;
      align-self: flex-start;
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) =>
        open ? "translateX(-20px)" : "translateX(0)"};
      margin-bottom: 7px;
      align-self: flex-start;
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      align-self: flex-start;
    }
  }
  @media screen and (min-width: 1051px) {
    display: none;
  }
`;
// const ArrowDiv = styled.div`
//   cursor: pointer;
//   z-index: 10;
//   font-size: 40px;
//   position: fixed;
//   top: 50%;
//   transform: translate(0, -37px);
//   transition: 0.6s ease-in-out;
//   left: ${(props) => (props.navbarToggle ? "170px" : "25px")};
//   color: var(--silver);
//   @media screen and (max-width: 991px) {
//     /* start of medium tablet styles */
//     display: none;
//   }
// `;
// const NavLink = styled(BaseNavLink)`
//   text-decoration: none;
//   color: var(--silver);
// `;
// const LogoImg = styled.img`
//   animation: ${fadeIn} 1.5s ease-in;
//   height: 50px;
//   width: 50px;
//   position: fixed;
//   z-index: 10;
//   width: 50px;
//   top: 40px;
//   left: 40px;
//   @media screen and (max-width: 991px) {
//     /* start of phone and medium tablet styles */
//     display: none;
//   }
// `;
const ReserveTable = styled.a`
  z-index: 12;
  color: var(--white);
  cursor: pointer;
  position: fixed;
  right: 40px;
  top: 40px;
  width: 100%;
  max-width: 140px;
  text-align: center;
  padding: 5px 0;
  border: 1px solid var(--silver);
  border-radius: 10px;
  transition: 0.3s all;
  background-color: rgba(0, 0, 0, 0.2);
  & > span {
    font-weight: 300;
  }
  &:hover {
    background-color: var(--silver);
    color: #101010;
  }
  @media screen and (max-width: 1050px) {
    /* start of phone and medium tablet styles */
    background-color: rgba(0, 0, 0, 0.3);
    top: 20px;
    right: 20px;
    font-size: 14px;
    padding: 10px 0;
    color: var(--silver);
    /* display: ${({ theme }) => (theme === true ? "none" : "block")}; */
    animation: ${fadeIn} 1.5s ease-in;
    display: none;
  }
`;
const Language = styled.div`
  z-index: 12;
  color: var(--white);
  cursor: pointer;
  position: fixed;
  right: 195px;
  //temporary
  /* right: 40px; */
  top: 46px;
  vertical-align: middle;
  text-transform: uppercase;
  & > div {
    font-size: 16px;
  }
  & > div::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--silver);
    transition: width 0.3s ease-in;
  }
  & > div:hover::after {
    width: 100%;
  }
  &:hover {
    color: var(--silver);
    transition: ease-in-out 0.3s;
    font-size: 105%;
  }
  @media screen and (max-width: 1050px) {
    /* start of phone and medium tablet styles */
    /* display: ${({ theme }) =>
      theme.navbarToggle === true ? "none" : "flex"}; */
    animation: ${fadeIn} 1.5s ease-in;
    top: 20px;
    line-height: 43px;
    right: 180px;
    display: none;
  }
`;
export default App;
