import { createContext, useContext, useEffect, useReducer } from "react";
import { initialState, reducer } from "../reducer/reducer";
import { fetchDrinks, fetchFood } from "../utils/csvUtils";
export const OrochiContext = createContext();
export const OrochiProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [drinksDataArray, foodDataArray] = await Promise.all([
          fetchDrinks(),
          fetchFood(),
        ]);

        dispatch({ type: "SET_DRINKS_DATA", payload: drinksDataArray });
        dispatch({ type: "SET_FOOD_DATA", payload: foodDataArray });
      } catch (error) {
        console.error("Encountered an error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <OrochiContext.Provider value={{ state, dispatch }}>
      {children}
    </OrochiContext.Provider>
  );
};

export const useOrochi = () => useContext(OrochiContext);
