import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Cookies, getCookieConsentValue } from "react-cookie-consent"; // getCookieConsentValue, // Cookies,
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import styled, { css, keyframes } from "styled-components";
import { useOrochi } from "../context/OrochiContext";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
// import HomepageLogo from "../images/LOGO.webp";
// import HomepageLogoMobile from "../images/mobile/orochi_logo-mobile.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
// import { useWindowScrollPositions } from "../utils/useWindowScrollPositions";
// import homeBg from "../images/home/druken-dragon-home-desktop.webp";
// import homeMobileBg from "../images/home/druken-dragon-home-mobile.webp";
import useElementOnScreen from "../utils/useElementOnScreen";
import ResponsiveImage from "../components/Img/ResponsiveImage";
import getResponsiveImageUrl from "../utils/getResponsiveImageUrl";
// import PopUpBox from "../components/PopUpBox";

const Homepage = () => {
  const [containerRef] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  const {
    dispatch,
    state: { circleButton, navbarToggle },
  } = useOrochi();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [inView5] = useInView();
  const [ref6, inView6] = useInView();
  const [ref7, inView7] = useInView();
  // const navigate = useNavigate();
  // const { scrollY } = useWindowScrollPositions();
  const [cookies] = useState(
    null || Cookies.get(getCookieConsentValue())?.orochi
  );
  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    let views = [inView1, inView2, inView3, inView4, inView5, inView6, inView7];
    views.map(
      (el, ind) =>
        el === true && dispatch({ type: "view-index", payload: ind + 1 })
    );
  }, [dispatch, inView1, inView2, inView3, inView4, inView5, inView6, inView7]);
  const circleButtonFn = useCallback(
    (value) => {
      dispatch({ type: "change-circle", payload: value });
    },
    [dispatch]
  );
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  // useEffect(() => {
  //   dispatch({ type: "scrolling", payload: inView2 });
  // }, [inView2, dispatch]);
  return (
    <Div>
      {(circleButton === 1 || circleButton === 7) && (cookies || !cookies) ? (
        <ArrowLink
          view7={inView7}
          href={inView7 === true ? `#${t("section1")}` : `#${t("section2")}`}
          aria-label="brings you to the next section"
          onClick={() => dispatch({ type: "bouncy-down-arrow", payload: 1 })}
        >
          {inView7 === true ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
          <div></div>
        </ArrowLink>
      ) : null}
      {circleButton === 1 && (
        <ArrowBackground view7={inView7}></ArrowBackground>
      )}
      <CircleButtons circle={{ circleButton, navbarToggle }}>
        <li>
          <a
            href={`#${t("section1")}`}
            aria-label="navigate to the first one"
            onClick={() => circleButtonFn(1)}
            className={circleButton === 1 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("section2")}`}
            aria-label="navigate to the second one"
            onClick={() => circleButtonFn(2)}
            className={circleButton === 2 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("section3")}`}
            aria-label="navigate to the third one"
            onClick={() => circleButtonFn(3)}
            className={circleButton === 3 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("section4")}`}
            aria-label="navigate to the fourth one"
            onClick={() => circleButtonFn(4)}
            className={circleButton === 4 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        {/* <li>
          <a
            href={`#${t("section5")}`}
            aria-label="navigate to the fifth one"
            onClick={() => circleButtonFn(5)}
            className={circleButton === 5 ? "active" : undefined}
          >
            {""}
          </a>
        </li> */}
        <li>
          <a
            href={`#${t("section6")}`}
            aria-label="navigate to the sixth one"
            onClick={() => circleButtonFn(5)}
            className={circleButton === 6 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
        <li>
          <a
            href={`#${t("section7")}`}
            aria-label="navigate to the seventh one"
            onClick={() => circleButtonFn(5)}
            className={circleButton === 7 ? "active" : undefined}
          >
            {""}
          </a>
        </li>
      </CircleButtons>
      <Section id={t("section1")}>
        <Parallax speed={0}>
          <HomepageDiv
            ref={ref1}
            view1={inView1}
            bgImage={getResponsiveImageUrl(
              windowWidth >= 991
                ? "home/drunken-dragon-home-desktop2.webp"
                : "home/drunken-dragon-home-mobile.webp"
            )}
          >
            {/* <img
              src={windowWidth >= 991 ? HomepageLogo : HomepageLogoMobile}
              alt="logo"
            /> */}
            <ResponsiveImage
              src={"logos/drunken-dragon-top1.svg"}
              alt="logo-top"
            />
            <div ref={containerRef}></div>
            <ResponsiveImage
              src={"logos/drunken-dragon-bottom1.svg"}
              alt="logo-bottom"
            />
            <div>
              <h1>{t("h-o-h-o")}</h1>
              <p>{t("h-o-p-o")}</p>
              {/* <a
                href={`/pdf/8.5x11-dd-valentines-menu_${
                  i18n.language === "en" ? "EN" : "FR"
                }.pdf`}
                rel="noopener noreferrer"
                target="_blank"
                ref={containerRef}
              >
                {i18n.language === "en" ? "Valentines" : "Saint-Valentin"}
              </a> */}
              {/* <p>{t("h-o-p-t")}</p> */}
            </div>
            {/* <PopUpBox /> */}
            {/* <h2>{t("coming.soon")}</h2> */}
          </HomepageDiv>
        </Parallax>
      </Section>
      <Section id={t("section2")}>
        <Parallax speed={windowWidth >= 991 ? 3 : 0}>
          <SecondHomePage
            ref={ref2}
            view2={inView2}
            bgImage={getResponsiveImageUrl(
              "home/druken-dragon-Grilled_Element_BG.webp"
            )}
          >
            <ResponsiveImage
              src={
                windowWidth >= 991
                  ? "home/jp_text_01.webp"
                  : "mobile/Homepage_02_text.webp"
              }
              alt="logo"
              fluidImage
            />
            <SushiImg
              src={
                windowWidth >= 991
                  ? "home/druken-dragon-sushi.webp"
                  : "home/druken-dragon-sushi-mobile.webp"
              }
              alt="sushi"
              view2={inView2}
            />
          </SecondHomePage>
        </Parallax>
      </Section>
      <Section id={t("section3")}>
        <Parallax speed={windowWidth >= 991 ? 2 : 0}>
          <ThirdHomePage
            ref={ref3}
            view3={inView3}
            bgImage={getResponsiveImageUrl(
              windowWidth >= 991
                ? "home/drunken-dragon-culinary-craftsmanship.webp"
                : "home/drunken-dragon-culinary-craftsmanship-mobile.webp"
            )}
          >
            <div>
              <div>
                <h2>{t("h-three-h-o")}</h2>
                <div>
                  <p>{t("h-three-p-o")}</p>
                </div>
              </div>
            </div>
          </ThirdHomePage>
        </Parallax>
      </Section>
      <Section id={t("section4")}>
        <Parallax speed={windowWidth >= 991 ? 2 : 0}>
          <FourthHomePage
            ref={ref4}
            view4={inView4}
            bgImage={getResponsiveImageUrl(
              windowWidth >= 991
                ? "home/drunken-dragon-highest-quality-desktop.webp"
                : "home/drunken-dragon-highest-quality-mobile.webp"
            )}
          >
            <div>
              <div>
                <h2>{t("h-four-h-o")}</h2>
                <p>{t("h-four-p-o")}</p>
              </div>
            </div>
          </FourthHomePage>
        </Parallax>
      </Section>
      {/* <Section id={t("section5")}>
        <Parallax speed={windowWidth >= 991 ? 2 : 0}>
          <FifthHomePage ref={ref5} view5={inView5}>
            <div>
              <div>
                <h2>{t("h-five-h-o")}</h2>
                <p>{t("h-five-p-o")}</p>
                <p>{t("h-five-p-t")}</p>
              </div>
            </div>
          </FifthHomePage>
        </Parallax>
      </Section> */}
      <Section id={t("section6")}>
        <Parallax speed={windowWidth >= 991 ? 2 : 0}>
          <SixthHomePage
            ref={ref6}
            view6={inView6}
            bgImage={getResponsiveImageUrl(
              windowWidth >= 991
                ? "home/drunken-dragon-cocktails-desktop.webp"
                : "home/drunken-dragon-cocktails-mobile.webp"
            )}
          >
            <div>
              <div>
                <h2>{t("h-six-h-o")}</h2>
                <p>{t("h-six-p-o")}</p>
                {/* <p>{t("h-six-p-t")}</p> */}
              </div>
            </div>
          </SixthHomePage>
        </Parallax>
      </Section>
      <Section id={t("section7")}>
        <Parallax speed={windowWidth >= 991 ? 2 : 0}>
          <SeventhHomePage
            ref={ref7}
            view7={inView7}
            bgImage={getResponsiveImageUrl(
              windowWidth >= 991
                ? "home/drunken-dragon-legend-desktop.webp"
                : "home/drunken-dragon-legend-mobile.webp"
            )}
          >
            <div>
              <div>
                {/* <h2>{t("h-seven-h-o")}</h2>
                <p>{t("h-seven-p-o")}</p>
                <p>{t("h-seven-p-t")}</p>
                <p>{t("h-seven-p-three")}</p>
                <p>{t("h-seven-p-four")}</p> */}
                <Link
                  // theme={navbarToggle}
                  to="/the-legend"
                >
                  <span>
                    {i18n.language === "en" ? "The Legend" : "La Légende"}
                  </span>
                </Link>
                <Link
                  // theme={navbarToggle}
                  to="/about"
                >
                  <span>
                    {i18n.language === "en" ? "Our Story" : "Notre Histoire"}
                  </span>
                </Link>
              </div>
            </div>
          </SeventhHomePage>
        </Parallax>
        <FooterSection>
          <Footer />
        </FooterSection>
      </Section>
    </Div>
  );
};
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const Div = styled.div`
  width: 100vw;
  height: 100vh;
  scroll-behavior: smooth;
  transition: 0.5s ease-in-out;
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    overflow-x: hidden;
  }
`;
const bounce = keyframes`0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-10px);}`;

const ArrowLink = styled.a`
  position: fixed;
  z-index: 10;
  height: 49px;
  text-decoration: none;
  animation: ${bounce} 2s ease infinite;
  /* left: calc(50% - 17px); */
  transform: translateX(-50%, -50%);
  ${({ view7 }) =>
    view7 === true
      ? css`
          bottom: 80px;
        `
      : css`
          bottom: 40px;
        `};
  ${({ view7 }) =>
    view7 === true
      ? css`
          right: 40px;
        `
      : css`
          left: calc(50% + 34px);
          //temporary
          /* left: calc(50%); */
        `};
  /* ${({ view7 }) =>
    view7 === true
      ? css`
          bottom: 40px;
        `
      : css`
          bottom: 10%;
        `}; */
  font-size: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: var(--white);
  border: 2px solid var(--white);
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    border: 2px solid var(--silver);
    color: var(--silver);
  }
  & > div {
    margin-top: 15px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    ${({ view7 }) =>
      view7 === true
        ? css`
            right: 20px;
          `
        : css`
            left: calc(50% - 17px);
          `}
  }
`;
const slide = keyframes`
  0% {
    transform: translateY(+8%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(+8%);
  }
`;
const slide2 = keyframes`
from {
    transform: translateY(+100%);
  }
  to {
    transform: translateY(0%);
  }
`;
const ArrowBackground = styled.div`
  position: fixed;
  z-index: 2;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  width: 90px;
  background: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  ${({ view5 }) =>
    view5 === true
      ? css`
          display: none;
        `
      : css`
          display: block;
        `};
  @media screen and (min-width: 768px) and (max-width: 991px) {
    height: 180px;
  }
  @media screen and (min-width: 992px) {
    /* start of medium tablet styles */
    display: none;
  }
`;
const Section = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    height: auto;
    /* overflow: hidden; */
  }
`;
const FooterSection = styled.section`
  height: auto;
  width: 100vw;
  /* position: absolute; */
  bottom: 0;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    position: relative;
  }
`;
const HomepageDiv = styled.div`
  position: absolute;
  width: 100vw;
  height: 105vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  //temporaroly
  flex-direction: column;
  padding: 20px 40px 20px 167px;
  //temporary
  /* padding: 20px 40px; */
  & > img:first-child {
    height: 100%;
    width: 100%;
    max-width: 700px;
    height: max-content;
    animation: ${fadeIn} 1.4s ease-in;
  }
  & > img:nth-child(3) {
    position: relative;
    top: -30px;
    width: 100%;
    max-width: 480px;
    height: max-content;
    animation: ${fadeIn} 1.4s ease-in;
    //temporary
    /* opacity: ${({ theme }) => (theme.isScrolling ? "0" : "1")}; */
    transition: opacity 0.4s ease-in;
    margin-bottom: 32px;
  }
  & > div {
    text-align: center;
    max-width: 440px;
    color: var(--white);
    margin-top: 40px;
    animation: ${fadeIn} 1.8s ease-in;
    text-shadow: 1px 1px 8px black;
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 12px;
    }
    & > p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
      margin-top: 20px;
      margin-bottom: 0;
    }
    & > p:nth-child(3) {
      margin-top: 15px;
    }
    & > a {
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 20px auto;
      color: var(--white);
      text-align: center;
      padding: 5px 0;
      border: 1px solid var(--silver);
      border-radius: 10px;
      transition: 0.3s all;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      max-width: 200px;
      font-weight: 300;
      &:hover {
        background-color: var(--silver);
        color: #101010;
      }
      @media screen and (max-width: 1050px) {
        /* start of phone and medium tablet styles */
        font-size: 14px;
        padding: 10px 0;
        color: var(--silver);
      }
    }
  }
  & > div:nth-child(2) {
    margin-top: 0;
  }
  & > h2 {
    position: relative;
    bottom: -100px;
    color: var(--white);
    margin-top: 40px;
    font-weight: 200;
    /* text-transform: capitalize; */
    animation: ${fadeIn} 1.8s ease-in;
    text-align: center;
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    position: unset;
    background-position: top;
    top: 0;
    height: auto;
    min-height: 102vh;
    //temporary
    /* min-height: 100vh; */
    padding: 100px 20px 40px 20px;
    & > img:first-child {
      object-fit: contain;
      width: 100%;
      max-height: 120px;
      height: 100%;
      max-width: 350px;
      margin-bottom: 12px;
    }
    & > img:nth-child(3) {
      max-width: 240px;
      margin: 0;
    }
    & > div {
      color: var(--white);
      & > h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      & > p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }
      p:nth-child(2n + 3) {
        margin: 12px 0 0 0;
      }
    }
    & > h2 {
      font-size: 18px;
      margin: 0;
      bottom: -160px;
    }
  }
`;
const CircleButtons = styled.ul`
  z-index: 100;
  position: fixed;
  transition: 0.6s ease-in-out;
  right: 40px;
  /* left: ${(props) => (props.circle.navbarToggle ? "190px" : "50px")}; */
  top: 50%;
  transform: translate(calc(-50% + 15px), -50%);
  /* transform: translate(0, -145px); */
  /* margin-left: 25px; */
  /* padding-left: ${(props) =>
    props.circle.navbarToggle ? "10px" : "0px"}; */
  background-color: inherit;
  & > li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 2px solid var(--silver);
    width: 25px;
    height: 25px;
    margin-bottom: 39px;
    & > a:hover {
      background-color: var(--silver);
      transition: background-color 0.4s;
    }
    & > a {
      width: 17px;
      height: 17px;
      border-radius: 15px;
      cursor: pointer;
      &.active {
        background-color: var(--silver);
        transition: background-color 0.4s;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    display: none;
  }
`;
const SecondHomePage = styled.div`
  position: absolute;
  width: 100vw;
  height: 105vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > img:first-child {
    animation: ${(props) =>
      props.view2 === true &&
      css`
        ${slide} 4s ease-in-out infinite
      `};
    animation-fill-mode: both;
    width: 60%;
    height: auto;
    position: relative;
    top: 8%;
    object-fit: contain;
    @media screen and (max-width: 991px) {
      /* start of phone and medium tablet styles */
      width: 50%;
    }
  }
  /* & > div:last-child {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 650px;
    padding: 40px 20px;
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
    }
    & > p {
      color: var(--silver);
      margin-top: 20px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
  } */
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    position: relative;
    height: auto;
    min-height: 102vh;
    padding: 80px 0 0 0;
    /* & > div:last-child {
      & > h1 {
        font-size: 28px;
        line-height: 30px;
      }
    } */
  }
`;
const SushiImg = styled(ResponsiveImage)`
  position: relative;
  bottom: 4%;
  width: 64%;
  height: auto;
  object-fit: contain;
  animation: ${(props) =>
    props.view2 === true &&
    css`
      ${slide} 4s ease-in-out infinite
    `};
  animation-fill-mode: both;
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    width: 100%;
  }
  @media screen and (max-width: 375px) {
    /* start of phone styles */
    width: 100%;
  }
`;
const ThirdHomePage = styled.div`
  position: absolute;
  width: 100vw;
  height: 105vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  overflow: hidden;
  padding: 60px 40px 40px 167px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: 38%;
    & > div {
      animation: ${(props) =>
        props.view3 === true &&
        css`
          ${slide2} 2s ease
        `};
      width: 80%;
      font-weight: lighter;
      color: var(--white);
      margin: 0 auto;
      text-shadow: 1px 1px 8px black;
      & > h2 {
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
      }
      & > div {
        height: auto;
        width: 100%;
        margin-bottom: 20px;
        & > p {
          margin-top: 20px;
          font-size: 16px;
          line-height: 30px;
          font-weight: 300;
        }
        p:nth-child(2n + 3) {
          margin: 12px 0 0 0;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    position: relative;
    width: 100%;
    height: auto;
    /* min-height: 102vh; */
    padding: 60px 20px;
    justify-content: unset;
    align-items: unset;
    background-color: var(--black);
    background-size: contain;
    background-position: top;
    top: 0;
    & > div {
      width: 100%;
      position: unset;
      & > div {
        padding-top: 80vw;
        height: auto;
        width: 100%;
        color: var(--white);
        & > h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        & > div {
          & > p {
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`;
const FourthHomePage = styled.div`
  position: absolute;
  width: 100vw;
  height: 105vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  padding: 60px 40px 40px 167px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 38%;
    height: auto;
    & > div {
      animation: ${(props) =>
        props.view4 === true &&
        css`
          ${slide2} 2s ease
        `};
      color: var(--white);
      text-align: left;
      font-weight: lighter;
      width: 80%;
      margin: 0 auto;
      text-shadow: 1px 1px 8px black;
      & > h2 {
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
      & > p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }
      p:nth-child(2n + 3) {
        margin: 12px 0 0 0;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    position: relative;
    height: auto;
    /* min-height: 102vh; */
    padding: 60px 20px;
    align-items: unset;
    background-color: var(--black);
    background-size: contain;
    background-position: top;
    top: 0;
    & > div {
      justify-content: unset;
      height: auto;
      width: 100%;
      & > div {
        padding-top: 80vw;
        height: auto;
        width: 100%;
        color: var(--white);
        & > h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        & > p {
          line-height: 28px;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
// const FifthHomePage = styled.div`
//   position: absolute;
//   width: 100vw;
//   height: 105vh;
//   background-image: url("/img/home/drunken-dragon-whisky-desktop.webp");
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding: 60px 40px;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   & > div {
//     width: 38%;
//     & > div {
//       animation: ${(props) =>
//         props.view5 === true &&
//         css`
//           ${slide2} 2s ease
//         `};
//       color: var(--white);
//       font-weight: lighter;
//       width: 80%;
//       margin: 0 auto;
//       & > h2 {
//         color: var(--gold_logo);
//         font-weight: 300;
//         text-transform: uppercase;
//         font-size: 36px;
//         line-height: 40px;
//         margin-bottom: 20px;
//       }
//       & > p {
//         margin-top: 20px;
//         font-size: 16px;
//         line-height: 30px;
//         font-weight: 300;
//       }
//       p:nth-child(2n + 3) {
//         margin: 12px 0 0 0;
//       }
//     }
//   }
//   @media screen and (max-width: 991px) {
//     /* start of phone and tablet styles */
//     position: relative;
//     height: auto;
//     /* min-height: 102vh; */
//     padding: 60px 20px;
//     justify-content: unset;
//     background-color: var(--black);
//     background-image: url("/img/home/drunken-dragon-whisky-mobile.webp");
//     background-size: contain;
//     background-position: top;
//     top: 0;
//     & > div {
//       position: unset;
//       width: 100%;
//       & > div {
//         padding-top: 80vw;
//         height: auto;
//         width: 100%;
//         color: var(--white);
//         & > h2 {
//           font-size: 28px;
//           line-height: 32px;
//           margin-bottom: 16px;
//         }
//         & > p {
//           line-height: 28px;
//           margin-bottom: 16px;
//         }
//         & > button {
//           display: flex;
//           margin: 0 auto;
//           padding: 12px 30px;
//           outline: none;
//           border: none;
//           background: var(--silver);
//           border-radius: 35px;
//           font-size: 18px;
//           font-weight: 300;
//           color: black;
//         }
//       }
//     }
//   }
// `;
const SixthHomePage = styled.div`
  position: absolute;
  width: 100vw;
  height: 105vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 40px 60px 167px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    width: 50%;
    & > div {
      animation: ${(props) =>
        props.view6 === true &&
        css`
          ${slide2} 2s ease
        `};
      color: var(--white);
      text-align: left;
      font-weight: lighter;
      width: 75%;
      margin: 0 auto;
      text-shadow: 1px 1px 8px black;
      & > h2 {
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
        max-width: 80%;
      }
      & > p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }
      p:nth-child(2n + 3) {
        margin: 12px 0 0 0;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    position: unset;
    height: auto;
    /* min-height: 102vh; */
    padding: 60px 20px;
    justify-content: unset;
    background-color: var(--black);
    background-size: contain;
    background-position: top;
    top: 0;
    & > div {
      position: unset;
      width: 100%;
      & > div {
        padding-top: 80vw;
        height: auto;
        width: 100%;
        color: var(--white);
        & > h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        & > p {
          line-height: 28px;
          margin-bottom: 16px;
        }
        & > button {
          display: flex;
          margin: 0 auto;
          padding: 12px 30px;
          outline: none;
          border: none;
          background: var(--silver);
          border-radius: 35px;
          font-size: 18px;
          font-weight: 300;
          color: black;
        }
      }
    }
  }
`;
const SeventhHomePage = styled.div`
  position: relative;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 38%;
    & > div {
      animation: ${(props) =>
        props.view7 === true &&
        css`
          ${slide2} 2s ease
        `};
      color: var(--white);
      /* text-align: left; */
      font-weight: lighter;
      width: 80%;
      margin: 0 auto;
      /* text-shadow: 1px 1px 8px black; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: center;
      & > a {
        color: var(--white);
        cursor: pointer;
        width: 100%;
        /* max-width: 200px; */
        text-align: center;
        padding: 15px 0;
        border: 1px solid var(--silver);
        border-radius: 10px;
        transition: 0.3s all;
        background-color: rgba(0, 0, 0, 0.2);
        font-size: 20px;
        & > span {
          font-weight: 300;
        }
        &:hover {
          background-color: var(--silver);
          color: #101010;
        }
      }
      /* & > h2 {
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
      & > p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }
      p:nth-child(2n + 3) {
        margin: 12px 0 0 0;
      } */
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > div {
      transform: none;
      & > div {
        & > button {
          display: flex;
          margin-top: 30px;
          padding: 12px 30px;
          outline: none;
          border: 1px solid var(--silver);
          background: inherit;
          border-radius: 35px;
          font-size: 18px;
          font-weight: 300;
          color: var(--silver);
          &:hover {
            background: var(--silver);
            color: black;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    position: unset;
    height: auto;
    /* min-height: 102vh; */
    padding: 20px;
    justify-content: unset;
    background-color: var(--black);
    background-size: contain;
    background-position: top;
    top: 0;
    & > div {
      position: unset;
      width: 100%;
      & > div {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 70vw;
        height: auto;
        width: 100%;
        color: var(--white);
        & > a {
          background-color: rgba(0, 0, 0, 0.3);
          top: 20px;
          right: 20px;
          font-size: 14px;
          padding: 10px 0;
          max-width: 400px;
          /* display: ${({ theme }) => (theme === true ? "none" : "block")}; */
          animation: ${fadeIn} 1.5s ease-in;
        }
        /* & > h2 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        & > p {
          line-height: 28px;
          margin-bottom: 16px;
        }
        & > button {
          display: flex;
          margin: 0 auto;
          padding: 12px 30px;
          outline: none;
          border: none;
          background: var(--silver);
          border-radius: 35px;
          font-size: 18px;
          font-weight: 300;
          color: black;
        } */
      }
    }
  }
`;
export default Homepage;
