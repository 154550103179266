import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
*,
    *::before,
    *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
  :root {
    --silver: #CDC5BF;
    --gold: #a16a41;
    --gold_logo: #B59771;
    --white: #fff;
    --black: #000000;
    --scroll_thumb: #7E7B79;
    --scroll_thumb_hover: #CDC5BF;
    --scroll_track: #353838;
  }
  body {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    font-weight: 300;
    background-color: var(--black);
    ul, ol {
    list-style: none;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
  }
`;

export default GlobalStyles;
