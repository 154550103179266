/* eslint-disable no-unused-vars */
import React, { useCallback, memo, useEffect } from "react";
import styled from "styled-components";
import { BsCircle } from "react-icons/bs";
import { useOrochi } from "../context/OrochiContext";
import { useNavigate } from "react-router-dom";
// import { drinksData } from "../data/drinks";
import { useTranslation } from "react-i18next";
import useElementOnScreen from "../utils/useElementOnScreen";
import { useInView } from "react-intersection-observer";

const Drinks = memo(({ windowWidth, handleSelectedCategoryDrinks }) => {
  const [containerRef] = useElementOnScreen({
    root: null,
    rootMargin: "40px",
    threshold: 0.5,
  });
  const { i18n, t } = useTranslation();
  const {
    dispatch,
    state: { menuButtons, drinksData },
  } = useOrochi();
  const changeBackgroundFn = useCallback(
    (value) => {
      dispatch({ type: "change-menu-bg", payload: value });
    },
    [dispatch]
  );
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  const navigate = useNavigate();
  useEffect(() => {
    let views = [inView1, inView2, inView3, inView4];
    views.map((el, ind) => el === true && handleSelectedCategoryDrinks(ind));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView1, inView2, inView3, inView4]);
  // console.log(windowWidth)
  return (
    <Main>
      <section id="cocktails">
        <div ref={ref1}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Cocktails" : "Cocktails"}
          </h5>
        </span>
        <div>
          {drinksData?.map((el) =>
            el.category === "Cocktails" ? (
              <span key={Math.random() * 14000}>
                <h6>{i18n.language === "en" ? el.name : el.name_fr}</h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
          <p></p>
        </div>
      </section>
      <section id="mocktails">
        <div ref={ref2}></div>
        <span>
          <h5>MOCKTAILS</h5>
        </span>
        <div ref={containerRef}>
          {drinksData?.map((el) =>
            el.category === "Mocktails" ? (
              <span key={Math.random() * 14000}>
                <h6>{i18n.language === "en" ? el.name : el.name_fr}</h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
          <p></p>
        </div>
      </section>
      <section id="whisky">
        <div ref={ref3}></div>
        <span>
          <h5>WHISKY</h5>
        </span>
        <div ref={containerRef}>
          {drinksData?.map((el) =>
            el.category === "Whisky" ? (
              <span key={Math.random() * 14000}>
                <h6>{i18n.language === "en" ? el.name : el.name_fr}</h6>
                {el?.price_btl ? (
                  <p>
                    {i18n.language === "en"
                      ? `$${el?.price_gl} gl / $${el?.price_btl} btl`
                      : `${el?.price_gl}$ verre / ${el?.price_btl}$ btl`}
                  </p>
                ) : (
                  <p>
                    {i18n.language === "en"
                      ? `$${el?.price_gl} gl`
                      : `${el?.price_gl}$ verre`}
                  </p>
                )}
              </span>
            ) : null
          )}
          <p></p>
        </div>
      </section>
      <section id="sakes">
        <div ref={ref4}></div>
        <span>
          <h5>{i18n.language === "en" ? "Sakes" : "Sakés"}</h5>
        </span>
        <div ref={containerRef}>
          {drinksData?.map((el) =>
            el.category === "Sakes" ? (
              <span key={Math.random() * 14000}>
                <h6>{i18n.language === "en" ? el.name : el.name_fr}</h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
          <p></p>
        </div>
      </section>
      <section>
        <div ref={ref5}></div>
        <div>
          <a
            ref={containerRef}
            href={`/pdf/DG_Drunken-Dragon_Drink_Menu_${
              i18n.language === "en" ? "EN" : "FR"
            }.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en"
              ? "Complete drink menu"
              : "Menu complet des boissons"}
          </a>
          {/* <a
            href="/pdf/PG_WINE_MENU.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Wine menu" : "Menu des vins"}
          </a> */}
        </div>
      </section>
    </Main>
  );
});
const Main = styled.main`
  width: 100%;
  height: 100%;
  max-height: calc(80% - 20px);
  scroll-behavior: smooth;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  padding-right: 50px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--scroll_track);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scroll_thumb);
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scroll_thumb_hover);
  }
  & > section {
    position: relative;
    & > div:first-child {
      position: absolute;
      height: 200px;
      margin-top: 400px;
    }
    & > span {
      display: flex;
      & > h5 {
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        margin-top: 7px;
        /* margin-left: 10px; */
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
      }
      & > span {
        margin-right: 10px;
        font-size: 20px;
        color: var(--silver);
        z-index: 4;
      }
    }
    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10%;
      /* margin-left: 40px; */
      & > span {
        /* margin-bottom: 15px; */
        /* cursor: pointer; */
        /* padding-left: 5px; */
        height: auto;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        & > h6 {
          margin: 5px 0;
          font-weight: 500;
          max-width: 200px;
          & > small {
            font-weight: 300;
          }
        }
        & > p {
          align-self: flex-start;
          margin: 0;
          font-size: 16px;
          line-height: 30px;
          font-weight: 500;
          text-align: right;
          text-transform: lowercase;
        }
        /* &:hover {
          border: 1px solid var(--silver);
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.7);
          color: black;
          & > h6 {
            font-weight: 300;
          }
          & > p {
            font-size: 16px;
            line-height: 30px;
            font-weight: 300;
          }
        } */
      }
    }
  }
  & > section:last-child {
    & > div:last-child {
      padding-top: 25px;
      display: flex;
      justify-content: center;
      text-align: center;
      & > a {
        color: var(--white);
        text-align: center;
        padding: 5px 0;
        border: 1px solid var(--silver);
        border-radius: 10px;
        transition: 0.3s all;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 280px;
        font-weight: 300;
        &:hover {
          background-color: var(--silver);
          color: #101010;
        }
        @media screen and (max-width: 1050px) {
          /* start of phone and medium tablet styles */
          font-size: 14px;
          padding: 10px 0;
          color: var(--silver);
        }
      }
    }
  }
  @media screen and (min-width: 2000px) {
    & > section {
      position: relative;
      & > div:first-child {
        position: absolute;
        height: 100px;
        margin-top: 900px;
      }
    }
    & > section:last-child {
      & > div {
        margin-bottom: 700px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    /* display: block; */
    gap: 0;
    padding: 8px 8px 0 0;
    position: relative;
    right: 0;
    left: 0;
    height: auto;
    max-height: calc(100% - 140px);
    ::-webkit-scrollbar {
      width: 2px;
    }
    & > section {
      & > span {
        position: relative;
        width: 100%;
        & > h5 {
          padding-bottom: 16px;
          font-weight: 300;
          font-size: 20px;
        }
        & > span {
          position: absolute;
          left: -20px;
          bottom: 18px;
        }
      }
      & > div:last-child {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        margin: 0;
        width: 100%;
        margin-bottom: 45px;
        & > span {
          padding: 0;
          width: 100%;
          margin: 0;
          height: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          /* &:hover {
            border: none;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.6);
          } */
          h6 {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            max-width: 135px;
          }
          p {
            margin: 0;
            line-height: 18px;
          }
        }
      }
    }
    & > section:last-child {
      & > div:first-child {
        margin-top: 0;
      }
      & > div:last-child {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        position: relative;
        z-index: 5;
      }
    }
  }
`;
export default Drinks;
