import React from "react";
import styled from "styled-components";
import { RiMapPin2Line, RiPhoneFill } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import GoogleMap from "../components/GoogleMap";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import getResponsiveImageUrl from "../utils/getResponsiveImageUrl";

const Contact = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <ContactDiv bgImage={getResponsiveImageUrl("contact_us_bg.webp")}>
        {/* <div>
          <h2>お問い合わせ</h2>
        </div> */}
        <ContactInfoDiv>
          <main>
            <h1>{t("contact-h-o")}</h1>
            <div>
              <div>
                <h3>{t("contact-h-t")}</h3>
                <div>
                  <RiMapPin2Line />
                  <div>
                    <p>
                      1500 {i18n.language === "en" ? "Unit" : "Unité"} C, QC-138{" "}
                      <br /> Kahnawake, QC <br /> J0L 1B0
                    </p>
                  </div>
                </div>
                <div>
                  <RiPhoneFill />
                  <a href="tel:1-450-635-7544"> 1-450-635-7544</a>
                </div>
                <div>
                  <GoMail />
                  <a href="mailto:info@drunkendragon.ca">
                    {" "}
                    info@drunkendragon.ca
                  </a>
                </div>
              </div>
              <div>
                <h3>{t("contact-hours")}</h3>
                <div>
                  {i18n.language === "en" ? (
                    <>
                      <p>Monday to Sunday</p>
                      <p>5:00PM to 11:00PM</p>
                    </>
                  ) : (
                    <>
                      <p>Lundi au dimanche</p>
                      <p>17h00 à 23h00</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
          <MapDiv>
            <GoogleMap samesite="lax" />
            <Directions
              href="https://www.google.com/maps/dir//Playground+1500+QC-138+Unit+C+Kahnawake,+QC+J0L+1B0/@45.3769003,-73.7061295,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cc91bfa372112f3:0x84343f2ce23c89ba!2m2!1d-73.7061295!2d45.3769003?entry=ttu"
              target="_blank"
            >
              {i18n.language === "en"
                ? "Get driving drections"
                : "Itinéraire pour s'y rendre"}
            </Directions>
          </MapDiv>
        </ContactInfoDiv>
        <div>
          <Footer />
        </div>
      </ContactDiv>
    </div>
  );
};
const ContactDiv = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 150px;
  color: var(--silver);
  & > div:first-child {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
  }
  & > div:last-child {
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    display: block;
    padding-left: 0;
    height: auto;
    & > div:first-child {
      max-width: 640px;
      & > h2 {
        right: 0;
        top: -15px;
        left: 140px;
        font-size: 28px;
        text-align: left;
      }
    }
    & > div:last-child {
      position: relative;
    }
  }
`;
const ContactInfoDiv = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  & > main {
    width: 100%;
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
    }
    & > div {
      & > div {
        & > h3 {
          margin: 20px 0;
          font-weight: 400;
          text-transform: uppercase;
        }
        & > div {
          margin-bottom: 16px;
          display: flex;
          & > svg {
            height: 24px;
          }
          & > p {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
        & > div {
          gap: 8px;
          align-items: flex-start;
          & > div {
            & > p {
              font-size: 16px;
              line-height: 28px;
              margin-bottom: 16px;
            }
          }
        }
      }
      & > div:last-child {
        & > h3 {
          margin-top: 32px;
        }
        & > div {
          & > p {
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1240px) {
    /* start of phone styles */
    flex-flow: column nowrap;
    margin: 0;
    justify-content: center;
    padding: 100px 20px 40px 20px;
    & > main {
      width: 100%;
      & > h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & > div {
          & > h3 {
            font-size: 24px;
            font-weight: 500;
          }
        }
        & > div:last-child {
          & > h3 {
            margin-top: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    & > main {
      & > div {
        flex-direction: column;
      }
    }
  }
`;
const MapDiv = styled.div`
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    display: block;
    width: 100%;
    border: 2px solid var(--green);
    height: 380px;
    padding-bottom: 60px;
    & > iframe {
      width: 100%;
      height: 100%;
      top: 15px;
      right: 15px;
    }
  }
`;
const Directions = styled.a`
  color: var(--white);
  cursor: pointer;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  padding: 5px 0;
  border: 1px solid var(--silver);
  border-radius: 10px;
  transition: 0.3s all;
  background-color: rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: var(--silver);
    color: #101010;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    padding: 10px 0;
    color: var(--silver);
  }
`;
export default Contact;
