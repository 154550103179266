import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Footer from "../components/Footer";
import { Noindex } from "../Noindex";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <TermsOfUseDiv>
      <div>
        <h1>{t("termsAndConditions")}</h1>

        <h3>{t("introduction")}</h3>

        <p>{t("introductionText")}</p>

        <h3>{t("intellectualProperty")}</h3>

        <p>{t("intellectualPropertyText")}</p>

        <h3>{t("limitationsOfUse")}</h3>

        <p>{t("limitationsOfUseText1")}</p>
        <ul>
          <li>{t("limitationsOfUseText2")}</li>
          <li>{t("limitationsOfUseText3")}</li>
          <li>{t("limitationsOfUseText4")}</li>
        </ul>

        <h3>{t("userContent")}</h3>

        <p>{t("userContentText")}</p>

        <h3>{t("limitationsOfLiability")}</h3>

        <p>{t("limitationsOfLiabilityText")}</p>

        <h3>{t("reservationPolicy")}</h3>

        <p>{t("reservationPolicyText1")}</p>
        <p>{t("reservationPolicyText2")}</p>

        <h3>{t("privacyPolicy")}</h3>

        <p>
          {t("privacyPolicyText")} <a href="##">{t("privacyPolicyLink")}</a>
        </p>

        <h3>{t("changesToTerms")}</h3>

        <p>{t("changesToTermsText")}</p>

        <h3>{t("contactUs")}</h3>

        <p>
          {t("contactUsText")} <a href="##">{t("contactUsLink")}</a>
        </p>
      </div>
      <Footer />
      <Noindex />
    </TermsOfUseDiv>
  );
};

const TermsOfUseDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.95);
  color: var(--silver);
  padding-left: 167px;
  & > div:first-child {
    padding: 120px 40px 100px 40px;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 32px;
    }
    & > h3 {
      margin-top: 20px;
      font-weight: 300;
      text-transform: capitalize;
      font-size: 24px;
      padding-bottom: 10px;
      color: var(--gold_logo);
    }
    & > p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
    & > ul {
      list-style: disc;
      padding-left: 20px;
    }
  }
  & > div:nth-child(2) {
    padding-left: 0;
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    padding-left: 0;
    & > div:first-child {
      padding-bottom: 40px;
      & > h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 32px;
      }
      & > h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      & > p {
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }
`;
export default TermsOfUse;
