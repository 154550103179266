import React from "react";
import { Helmet } from "react-helmet-async";

export const Noindex = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
      <link rel="alternate" hreflang="en" href="/privacy-policy" />
      <link rel="alternate" hreflang="en" href="/terms-of-use" />
      <link rel="alternate" hreflang="fr" href="//privacy-policy" />
      <link rel="alternate" hreflang="fr" href="/terms-of-use" />
    </Helmet>
  );
};
