import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Noindex } from "../Noindex";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <PrivacyPolicyDiv>
      <div>
        <h1>{t("privacyPolicy.title")}</h1>

        <p>{t("privacyPolicy.introduction")}</p>
        <p>{t("privacyPolicy.information")}</p>
        <p>{t("privacyPolicy.informationContent")}</p>
        <ul>
          <li>{t("privacyPolicy.informationPoint1")}</li>
          <li>{t("privacyPolicy.informationPoint2")}</li>
          <li>{t("privacyPolicy.informationPoint3")}</li>
        </ul>

        <h3>{t("privacyPolicy.howWeUse")}</h3>
        <p>{t("privacyPolicy.howWeUseContent")}</p>
        <ul>
          <li>{t("privacyPolicy.howWeUsePoint1")}</li>
          <li>{t("privacyPolicy.howWeUsePoint2")}</li>
          <li>{t("privacyPolicy.howWeUsePoint3")}</li>
          <li>{t("privacyPolicy.howWeUsePoint4")}</li>
        </ul>

        <h3>{t("privacyPolicy.howWeStore")}</h3>
        <p>{t("privacyPolicy.howWeStoreContent")}</p>

        <h3>{t("privacyPolicy.sharingInformation")}</h3>
        <p>{t("privacyPolicy.sharingInformationContent")}</p>

        <h3>{t("privacyPolicy.cookies")}</h3>
        <p>{t("privacyPolicy.cookiesContent")}</p>

        <h3>{t("privacyPolicy.yourRights")}</h3>
        <p>{t("privacyPolicy.yourRightsContent")}</p>

        <h3>{t("privacyPolicy.changes")}</h3>
        <p>{t("privacyPolicy.changesContent")}</p>

        <h3>{t("privacyPolicy.contactUs")}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t("privacyPolicy.contactUsContent"),
          }}
        ></p>
      </div>
      <Footer />
      <Noindex />
    </PrivacyPolicyDiv>
  );
};

const PrivacyPolicyDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.95);
  color: var(--silver);
  padding-left: 167px;
  & > div:first-child {
    padding: 120px 40px 100px 40px;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    & > h1 {
      color: var(--gold_logo);
      font-weight: 300;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 32px;
    }
    & > h3 {
      margin-top: 20px;
      text-transform: capitalize;
      font-size: 24px;
      padding-bottom: 10px;
      color: var(--gold_logo);
      font-weight: 300;
    }
    & > p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
    & > ul {
      list-style: disc;
      padding-left: 20px;
    }
  }
  & > div:nth-child(2) {
    padding-left: 0;
  }
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 991px) {
    padding-left: 0;
    & > div:first-child {
      padding-bottom: 40px;
      & > h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 32px;
      }
      & > h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      & > p {
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }
`;

export default PrivacyPolicy;
