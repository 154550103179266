import React from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import Footer from "../components/Footer";

const TheLegend = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv>
      <div>
        <main>
          <h1>
            {i18n.language === "en"
              ? "The Legendary Tale of Susanoo and Yamata no Orochi: A Myth That Shaped a Nation"
              : "Le Conte Légendaire de Susanoo et Yamata no Orochi : Un Mythe qui a Façonné une Nation"}
          </h1>
          {i18n.language === "en" ? (
            <p>
              In the annals of Japanese mythology, few tales are as captivating
              and significant as the legend of Susanoo and the fearsome Yamata
              no Orochi. This ancient story, steeped in Shinto beliefs, not only
              offers a glimpse into the rich tapestry of Japanese folklore but
              also reflects the cultural values{" "}
              <span>and spiritual perspectives of the nation.</span>
            </p>
          ) : (
            <p>
              Dans les annales de la mythologie japonaise, peu de récits sont
              aussi captivants et significatifs que la légende de Susanoo et du
              redoutable Yamata no Orochi. Cette histoire ancienne, imprégnée
              des croyances shintoïstes, offre non seulement un aperçu de la
              riche tapisserie du folklore japonais, mais reflète également les
              valeurs culturelles et{" "}
              <span>les perspectives spirituelles de la nation.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <h5>The Exiled Deity and a Serpent's Terror</h5>
          ) : (
            <h5>La Divinité Exilée et la Terreur du Serpent</h5>
          )}
          {i18n.language === "en" ? (
            <p>
              Our story begins with the banishment of Susanoo, the Shinto god of
              storms and the sea, from the heavenly realm. In his earthly exile,
              Susanoo's wanderings lead him to the Izumo province, where he
              encounters a grief-stricken elderly couple, Ashinazuchi and
              Tenazuchi, and their daughter, Kushinada-hime. They reveal a
              harrowing tale: a monstrous serpent, Yamata no Orochi, with eight
              heads and eight tails, has been terrorizing the land. Each year,
              it devours one of their daughters,{" "}
              <span>and now, only Kushinada-hime remains.</span>
            </p>
          ) : (
            <p>
              Notre histoire commence par le bannissement de Susanoo, le dieu
              shinto des tempêtes et de la mer, du royaume céleste. Dans son
              exil terrestre, les errances de Susanoo le mènent à la province
              d'Izumo, où il rencontre un couple âgé en deuil, Ashinazuchi et
              Tenazuchi, et leur fille, Kushinada-hime. Ils révèlent un récit
              effrayant : un serpent monstrueux, Yamata no Orochi, à huit têtes
              et huit queues, terrorise la région. Chaque année, il dévore l'une
              de leurs filles,{" "}
              <span>et maintenant, il ne reste que Kushinada-hime.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <h5>A Cunning Plan and a Heroic Battle</h5>
          ) : (
            <h5>Un Plan Ingénieux et une Bataille Héroïque</h5>
          )}
          {i18n.language === "en" ? (
            <p>
              Moved by their plight, Susanoo devises a daring plan. He
              transforms Kushinada-hime into a comb for safekeeping and
              instructs the couple to prepare eight gates with eight platforms,
              each holding a vat of sake. When the Orochi arrives, lured by the
              aroma of sake, it dips each of its eight headsinto the vats
              <span>and soon becomes intoxicated.</span>
            </p>
          ) : (
            <p>
              Ému par leur détresse, Susanoo conçoit un plan audacieux. Il
              transforme Kushinada-hime en peigne pour la protéger et demande au
              couple de préparer huit portes avec huit plates-formes, chacune
              contenant un tonneau de saké. Lorsque l'Orochi arrive, attiré par
              l'arôme du saké, il plonge chacune de ses huit têtes dans les
              tonneaux <span>et devient bientôt ivre.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              Seizing the moment, Susanoo attacks the weakened Orochi. The
              battle is fierce and tumultuous, shaking the mountains and the
              rivers. Finally, Susanoo emerges victorious, slaying the beast. In
              the aftermath, while dissecting the Orochi, he discovers a
              remarkable sword, the Kusanagi-no-Tsurugi, in one of its tails.
              This sword would later become one of the three Imperial Regalia of
              Japan, symbolizing the divine <span>right of the Emperor.</span>
            </p>
          ) : (
            <p>
              Saisissant le moment, Susanoo attaque l'Orochi affaibli. La
              bataille est féroce et tumultueuse, secouant les montagnes et les
              rivières. Finalement, Susanoo émerge victorieux, tuant la bête.
              Après coup, en disséquant l'Orochi, il découvre une épée
              remarquable, le Kusanagi-no-Tsurugi, dans l'une de ses queues.
              Cette épée deviendra plus tard l'un des trois Regalia Impériaux du
              Japon, symbolisant le droit divin<span> de l'Empereur.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <h5>A Tale of Courage and Faith</h5>
          ) : (
            <h5>Un Récit de Courage et de Foi</h5>
          )}
          {i18n.language === "en" ? (
            <p>
              The story of Susanoo and Yamata no Orochi is more than a myth;
              it's a narrative that embodies the essence of bravery, cunning,
              and divine intervention. Susanoo's heroism and strategic prowess
              resonate deeply in the Japanese psyche,{" "}
              <span>symbolizing the triumph of good over evil.</span>
            </p>
          ) : (
            <p>
              L'histoire de Susanoo et Yamata no Orochi est plus qu'un mythe ;
              c'est un récit qui incarne l'essence du courage, de l'ingéniosité
              et de l'intervention divine. L'héroïsme et la prouesse stratégique
              de Susanoo résonnent profondément dans la psyché japonaise,
              <span>symbolisant le triomphe du bien sur le mal.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              Moreover, the tale underscores the importance of faith and respect
              for the gods in Shintoism. Susanoo's reconciliation with his
              sister, Amaterasu, the sun goddess, through the gift of the
              Kusanagi-no-Tsurugi, is a poignant moment, highlighting the themes
              of redemption <span>and familial bonds.</span>
            </p>
          ) : (
            <p>
              De plus, le récit souligne l'importance de la foi et du respect
              des dieux dans le shintoïsme. La réconciliation de Susanoo avec sa
              sœur, Amaterasu, la déesse du soleil, à travers le don du
              Kusanagi-no-Tsurugi, est un moment poignant, mettant en lumière
              les thèmes de la rédemption <span>et des liens familiaux.</span>
            </p>
          )}
          {i18n.language === "en" ? (
            <h5>An Enduring Legacy</h5>
          ) : (
            <h5>Un Héritage Durable</h5>
          )}
          {i18n.language === "en" ? (
            <p>
              Today, the legend of Susanoo and Yamata no Orochi continues to
              inspire and captivate. It's a testament to the power of
              storytelling and its ability to convey timeless values and ideals.
              As we delve into this myth, we not only explore a fascinating
              aspect of Japanese culture but also connect with the universal
              themes of heroism, sacrifice, and the eternal struggle{" "}
              <span>between light and darkness.</span>
            </p>
          ) : (
            <p>
              Aujourd'hui, la légende de Susanoo et Yamata no Orochi continue
              d'inspirer et de captiver. C'est un témoignage de la puissance du
              récit et de sa capacité à transmettre des valeurs et des idéaux
              intemporels. En nous plongeant dans ce mythe, nous explorons non
              seulement un aspect fascinant de la culture japonaise, mais nous
              nous connectons également aux thèmes universels du héros, du
              sacrifice et de la lutte éternelle{" "}
              <span>entre la lumière et les ténèbres.</span>
            </p>
          )}
        </main>
      </div>
      <Footer />
    </MainDiv>
  );
};
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  background-color: var(--black);
  & > div:first-child {
    position: relative;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    background-image: url("/img/home/drunken-dragon-legend-desktop.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 120px 0 0 167px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: rgba(0, 0, 0, 0.8);
      pointer-events: none;
    }
    & > main {
      position: relative;
      z-index: 5;
      margin-bottom: 3%;
      text-align: center;
      width: 100%;
      max-width: 1000px;
      height: auto;
      padding: 20px;
      color: var(--white);
      & > h1 {
        animation: ${fadeIn} 0.5s ease-in;
        margin-bottom: 32px;
        color: var(--gold_logo);
        font-weight: 300;
        text-transform: uppercase;
        font-size: 36px;
        line-height: 40px;
      }
      & > h5 {
        animation: ${fadeIn} 1.5s ease-in;
        margin: 16px 0;
      }
      & > p {
        animation: ${fadeIn} 1.5s ease-in;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
        & > span {
          white-space: nowrap;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    & > div:first-child {
      padding: 80px 60px;
    }
  }
  @media screen and (max-width: 991px) {
    /* position: unset; */
    height: auto;
    justify-content: unset;
    & > div:first-child {
      padding: 20px;
      justify-content: center;
      align-items: center;
      background-image: url("/img/home/drunken-dragon-legend-mobile.webp");
      background-size: contain;
      background-position: top;
      &::after {
        background-color: inherit;
      }
      & > main {
        padding: 0;
        width: 100%;
        transform: unset;
        margin: 0;
        padding: 80vw 0 20px 0;
        color: var(--white);
        & > h1 {
          font-size: 28px;
          line-height: 30px;
        }
        & > p {
        }
      }
    }
  }
`;
export default TheLegend;
