import React from "react";
import styled from "styled-components";

const Order = () => {
  return (
    <OrderDiv>
      <h1>Order</h1>
    </OrderDiv>
  );
};
const OrderDiv = styled.div`
  width: 100%;
`;
export default Order;
